.picture {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.footer {
  background-color: rgb(243, 243, 243);
}

li {
  padding-bottom: 6px;
}

.download:hover {
  color: white !important;
}
